<template>
<div>
<v-btn @click="generateReport">Télécharger le rapport (en construction)</v-btn>
<vue-html2pdf
		:show-layout="false"
		:float-layout="true"
		:enable-download="true"
		:preview-modal="true"
		:paginate-elements-by-height="1400"
		filename="Rapport des commandes"
		:pdf-quality="2"
		:manual-pagination="false"
		pdf-format="a4"
		pdf-orientation="landscape"
		pdf-content-width="800px"
		ref="html2Pdf"
	>
<section slot="pdf-content">
<h1>Listing des commandes</h1>
	<table style="width: 1000px;">
	<thead>
	<tr>
		<th width="5%" class="text-center">Numéro de commande</th>
		<th width="10%" class="text-center">Adresse de livraison</th>
		<th width="15%" class="text-center">Note du client</th>
		<th width="15%" class="text-center">Commande</th>
		<th width="15%" class="text-center">Notes</th>
	</tr>
	</thead>
	<tbody>
	<tr v-for="order in orders" :key="order.id" :style="order.status == 'completed' ? 'background-color:#d1d1d1': ''">
	<td>{{ order.id }}</td>
	<td>
		<b>Livraison: {{ "Mondial Relay (obligatoire pour le format A3 en France et Belgique)" === order.shipping_lines[0].method_title ? "Mondial Relay" : order.shipping_lines[0].method_title}}</b><br>
		{{order.shipping.first_name}} {{order.shipping.last_name}} <br>
		<span v-if="order.shipping.company">{{order.shipping.company}} <br></span>
		<span v-if="order.shipping.adresse_1">{{order.shipping.adresse_1}} <br></span>
		<span v-if="order.shipping.adresse_2">{{order.shipping.adresse_2}} <br></span>
		<span v-if="order.shipping.postcode || order.shipping.city">{{order.shipping.postcode}} {{order.shipping.city}}<br></span>
		<span v-if="order.shipping.state || order.shipping.country">{{order.shipping.state}} {{order.shipping.country}}<br></span>
	</td>
	<td>
		{{order.customer_note}}
	</td>
	<td>
		<v-container v-for="line in order.line_items" :key="line.id">
			<span>{{line.quantity}} x {{line.parent_name ? line.parent_name : line.name}} ({{line.total}} €)</span>
			<span v-for="meta in line.meta_data" :key="meta.id">
				<span style="color:#80ADBC; text-transform:capitalize" v-if="meta.key !='_reduced_stock'"><br>{{meta.key}} : {{meta.value}}</span>
			</span>
		</v-container>
	</td>
	<td>
		<div style="margin:20px; padding:20px"></div>
	</td>
	<td>
		<div style="margin:20px; padding:20px"></div>
	</td>
	</tr>
	</tbody>
	</table>
</section>
</vue-html2pdf>
</div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
name: "PdfReport",
props: {
orders: Array,
},
methods: {
	generateReport () {
		this.$refs.html2Pdf.generatePdf()
	}
},

components: {
VueHtml2pdf
}
}
</script>

<style>
@media print {
}


</style>